
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { fixDate, isValidNumber, stringifySafe, updateNestedObject } from '@/utils/general'
  import { PurchaseView } from '@/components/forms/view/PurchaseView'
  import { plainToInstance } from 'class-transformer'
  import { Payment, PaymentType } from '@/entities/finance'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import { Person, PersonAccount } from '@/entities/persons'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import { mapActions } from 'vuex'

@Component({
  components: {
    LinkedPerson,
    GDatePicker,
    GFiles,
    BaseForm,
    AutoResume,
  },
  methods: {
    ...mapActions('resources/form', ['updatePurchaseByPaymentId']),
  },
})
  export default class TreasurerExpenseForm extends PurchaseView {
  updatePurchaseByPaymentId!: (idPayment) => Promise<void>
  expense = plainToInstance(Payment, {})
  title = ''
  expenseTypes = ['debt_payment', 'traffic_ticket_payment', 'retention']
  paymentType: PaymentType[] = []
  showDetail = false
  idProcess = null
  metadata = null
  messageMissingAccount = ''
  person: null | Person = null
  accounts: PersonAccount[] = []
  documents = []

  formData = {
    document: [],
    date: null,
    amount: null,
    backup: [],
    comment: '',
    account: null,
    paymentType: null,
    beneficiary: null,
    beneficiaryAccount: null,
  }

  fields = {
    accounts: {
      properties: {
        label: 'Cuenta de cargo',
        itemText: 'bankAccount',
        itemValue: 'id',
        returnObject: true,
        required: true,
        rules: this.fieldRequired,
      },
      items: [],
    },
    backup: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
        icon: '',
      },
    },
    beneficiary: {
      properties: {
        properties: {
          label: 'Beneficiario',
          rules: this.fieldRequired,
          itemText: 'formInfo',
          itemValue: 'id',
          clearable: true,
          returnObject: true,
        },
      },
      rules: this.fieldRequired,
      items: [],
    },
  }

  async mounted () {
    await this.setMetadata()

    const { id, uid, title, metadata } = this

    if (isValidNumber(id) || isValidNumber(uid)) {
      const expenseId = id || uid

      await this.getExpenseInfo(expenseId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, Boolean(this.expense?.id))
    }
    await this.setTheBackup()
    this.displayDetail()
  }

  async setTheBackup () {
    const { backup } = this

    if (!backup) {
      return
    }

    const isExpenseForm = 'expenseForm' in backup && backup.expenseForm
    const isBeneficiary = 'beneficiary' in backup && backup.beneficiary

    if (isExpenseForm) {
      this.fields.beneficiary.items = [isExpenseForm.beneficiary]
      this.formData.beneficiary = isBeneficiary || isExpenseForm.beneficiary
      this.formData.paymentType = isExpenseForm?.paymentType
      this.formData.date = isExpenseForm?.date
      this.formData.account = isExpenseForm?.account
      this.formData.comment = isExpenseForm?.comment
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('ExpenseOrder', 'treasurer_expense')
    const { form } = metadata as Form
    this.title = form.title
    this.metadata = metadata
    const processPayment = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })

    this.idProcess = processPayment?.[0]?.id

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { name: { _eq: 'expense_payment' } }] },
    })

    this.setProperties(fileInfo, 'backup')

    this.paymentType = await this.fetchData({
      query: { name: 'find', model: 'PaymentType' },
      filter: { name: { _nin: ['financing', 'vehicle_partially_paid', 'bank_deposit', 'bill_exchange'] } },
    })
  }

  async getExpenseInfo (id) {
    const expense = await this.fetchData({
      query: { name: 'fetch', model: 'ExpenseOrder', params: { id } },
      force: true,
    })

    expense.deal.auto.attributes = (await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        id_deal: { _eq: expense.deal.id },
      },
    }))[0]
    expense.deal.auto.maintenances = await this.fetchData({
      query: { name: 'find', model: 'Maintenance' },
      filter: {
        id_auto: { _eq: expense.deal.auto.id },
      },
    })

    this.expense = expense
    this.formData.paymentType = expense?.type
    this.formData.date = expense?.date?.format('DD/MM/YYYY')
    this.formData.comment = expense?.comment
    this.formData.account = expense?.financialAccount
    this.formData.beneficiary = this.isPersonSystem ? null : expense?.paymentRecipient?.person
    this.formData.beneficiaryAccount = this.isPersonSystem ? null : expense?.recipientAccount
    this.accounts = this.isPersonSystem ? null : [expense?.recipientAccount]
    this.fields.beneficiary.items = this.isPersonSystem ? null : [expense?.paymentRecipient?.person]
    const idPurchase = expense?.paymentRecipient?.paymentOrder?.idProcessRecord
    const idProcess = expense?.paymentRecipient?.paymentOrder?.idProcess

    const photos = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: idPurchase } },
          { parameter: { id_process: { _eq: idProcess } } },
        ],
      },
      force: true,
    })

    this.documents = photos?.map(photo => {
      return {
        url: photo.file.uri,
        description: photo.parameter.description,
      }
    }) || []

    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { id_process: { _eq: this.idProcess } } },
        ],
      },
      force: true,
    })
    this.formData.backup = files?.filter(file => file.parameter.name === 'expense_payment')
    const spendingSupport = files?.length && files.filter(file => file.parameter.name === 'spending_support')?.length
      ? files?.filter(file => file.parameter.name === 'spending_support')?.map(file => {
        return {
          url: file.file.uri,
          description: file.parameter.description,
        }
      }) : []
    if (spendingSupport.length) {
      this.documents.push(...spendingSupport)
    }
  }

  setProperties (fileInfo, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo[0]
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  cancelExpense () {
    const { formData: { document, backup } } = this
    this.setBackup(null)
    const files = [...document, ...backup]

    this.close(files)
  }

  removePersonBackup () {
    const { backup } = this
    if (backup) {
      backup.beneficiary = null
      this.setBackup(backup)
    }
  }

  async goPerson () {
    const { formData } = this
    this.saveBackup()

    const idPerson = formData?.beneficiary?.id
    await this.$router.push({
      name: 'generic-person-nested',
      params: { model: 'Expense', id: idPerson },
    })
  }

  saveBackup () {
    const { backup, formData } = this

    if (backup) {
      backup.beneficiary = null
      this.setBackup(backup)
    } else {
      this.setBackup({ expenseForm: { ...formData } })
    }
  }

  displayDetail () {
    const { expense, metadata, formData, isPersonSystem } = this

    expense.accounts = [expense.recipientAccount]

    if (formData.beneficiary?.id) {
      expense.paymentRecipient.person = formData.beneficiary
    }
    if (isPersonSystem) {
      expense.paymentRecipient.person = null
    }
    const idPurchase = expense?.paymentRecipient?.paymentOrder?.idProcessRecord

    expense.processAttachments = [
      {
        idProcessRecord: idPurchase,
        tableName: 'purchase_order',
      },
      {
        idProcessRecord: expense.id,
        tableName: 'payment',
        allowedFiles: [
          // 'expense_payment',
          'spending_support',
        ],
      },
    ]

    this.metadata = {
      data: expense,
      metadata,
    }

    this.showDetail = Boolean(expense.id)
  }

  async send () {
    const isOverLimit = this.formData.comment?.length > 256 || this.formData.paymentType.name === 'electronic_transfer' ? Boolean(this.messageMissingAccount.length) : false

    if (!this.$refs.form.validate() || isOverLimit) {
      await this.setFieldError()
      return
    }
    this.loadingForm = true
    const { expense, formData, fields, idProcess } = this

    const status = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'successful' } } },
          { status: { process: { table_name: { _eq: 'payment' } } } },
        ],
      },
    })
    const closed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    if (formData.paymentType?.id) {
      await this.pushData({
        model: 'Payment',
        fields: {
          id: expense.id,
          id_payment_type: formData.paymentType.id,
        },
      })
    }

    const payment = await this.fetchData({
      query: { name: 'fetch', model: 'Payment', params: { id: expense?.id } },
      force: true,
    })

    if (payment?.paymentRecipient?.person?.id !== formData.beneficiary?.id) {
      const findRecipient = await this.fetchData({
        query: { name: 'find', model: 'PaymentRecipient' },
        filter: {
          _and: [
            { id_payment_order: { _eq: payment.paymentRecipient.paymentOrder.id } },
            { id_payment_order_item: { _eq: payment.paymentRecipient.paymentOrderItem.id } },
            { id_person: { _eq: formData.beneficiary.id } },
          ],
        },
        force: true,
      })

      if (findRecipient.length) {
        await this.pushData({
          model: 'Payment',
          fields: {
            id: payment.id,
            id_payment_recipient: findRecipient[0].id,
          },
        })
      } else {
        const recipient = await this.pushData({
          model: 'PaymentRecipient',
          fields: {
            id_payment_order: payment.paymentRecipient.paymentOrder.id,
            id_payment_order_item: payment.paymentRecipient.paymentOrderItem.id,
            id_person: formData.beneficiary.id,
          },
        })

        await this.pushData({
          model: 'Payment',
          fields: {
            id: payment.id,
            id_payment_recipient: recipient.id,
          },
        })
      }
    }

    await this.pushData({
      model: 'Payment',
      fields: {
        id: expense.id,
        comment: formData.comment,
        date: fixDate(formData.date),
        id_process_status: closed[0]?.id,
        id_closing_reason: status?.[0]?.id,
        id_financial_account: formData.account.id,
        id_responsible: this.idEmployee,
        id_recipient_account: formData?.beneficiaryAccount?.id,
      },
    })

    await this.handleFileType(formData.backup, { properties: fields?.backup?.properties }, idProcess, expense.id)

    if (this.isAutoPayment) {
      await this.updatePurchaseByPaymentId(expense.id)
    }
    this.loadingForm = false
    await this.close()
  }

  async searchPerson ({ input }) {
    if (!input?.length || input?.length < 2) return
    const search = {
      items: {
        uid: 'person',
        where: {
          _and: [
            {
              name_person_type: { _neq: 'system' },
            },
            {
              _or: [
                {
                  main_phone: {
                    _ilike: '@',
                  },
                },
                {
                  uid: {
                    _ilike: '@',
                  },
                },
                {
                  full_name: {
                    _ilike: '@',
                  },
                },
                {
                  company_name: {
                    _ilike: '@',
                  },
                },
                {
                  alias: {
                    _ilike: '@',
                  },
                },
              ],
            },
          ],
        },
        triggers: [
          'update:search-input',
        ],
      },
    }

    const query = updateNestedObject(search.items.where, '_eq', this.replaceSpace(input), ['name_person_type'])
    this.fields.beneficiary.items = await this.findPersonSearch(query)
  }

  get change () {
    const { formData } = this

    return stringifySafe([formData])
  }

  get bindBeneficiary () {
    const { fields: { beneficiary } } = this

    if (!beneficiary) return {}

    return { ...beneficiary.properties, items: beneficiary.items }
  }

  get isPersonSystem () {
    const { expense } = this

    return expense?.paymentRecipient?.person?.type?.name === 'system'
  }

  get isChangeablePayment () {
    const { expense, expenseTypes } = this

    return expenseTypes.includes(expense?.paymentRecipient?.paymentOrderItem?.name)
  }

  get isAutoPayment () {
    const { expense } = this

    return expense?.paymentRecipient?.paymentOrderItem?.name === 'auto_payment'
  }

  get disable () {
    const { expense } = this

    return expense?.status?.isToConfirm || expense?.status?.isPending || expense?.status?.isToUpdating || Boolean(expense?.closingReason?.id)
  }

  get paymentEditBeneficiary () {
    const { expense } = this
    const allow = ['retention', 'traffic_ticket_payment']

    return allow.includes(expense?.paymentRecipient?.paymentOrderItem?.processExpense?.expense?.name)
  }

  get paymentTypeIsElectronic () {
    const type = ['electronic_transfer', 'bank_deposit']

    return type.includes(this.formData.paymentType?.name)
  }

  @Watch('formData.paymentType', { immediate: true })
  async onPaymentChange (val) {
    if (val?.name === 'electronic_transfer' && this.formData?.beneficiary?.id) {
      const accounts = await this.fetchData({
        query: { name: 'find', model: 'PersonAccount' },
        filter: { _and: [{ id_person: { _eq: this.formData.beneficiary.id } }, { active: { _eq: true } }] },
        force: true,
      })

      if (!accounts?.length) {
        this.messageMissingAccount = 'El beneficiario no tiene cuentas activas. Agrega una para el tipo de pago seleccionado.'
      } else {
        this.messageMissingAccount = ''
        this.accounts = this.isPersonSystem ? accounts.filter(account => !this.fields.accounts?.items?.map(item => item.id).includes(account.id)) : accounts
      }
    } else {
      this.messageMissingAccount = ''
    }
  }

  @Watch('formData.beneficiary', { deep: true, immediate: true })
  async onPersonChange (val) {
    if (!val?.id) return

    if (this.expense?.type?.name === 'electronic_transfer') {
      const accounts = await this.fetchData({
        query: { name: 'find', model: 'PersonAccount' },
        filter: { _and: [{ id_person: { _eq: val.id } }, { active: { _eq: true } }] },
        force: true,
      })

      if (!accounts?.length) {
        this.messageMissingAccount = 'El beneficiario no tiene cuentas activas. Agrega una para el tipo de pago seleccionado.'
      } else {
        this.messageMissingAccount = ''
        this.accounts = this.isPersonSystem ? accounts.filter(account => !this.fields.accounts?.items?.map(item => item.id).includes(account.id)) : accounts
      }
    } else {
      this.messageMissingAccount = ''
    }

    this.displayDetail()
  }

  @Watch('formData.paymentType', { deep: true })
  async onPaymentTypeChange (val) {
    if (!val?.name) return

    if (val?.name === 'cash') {
      this.formData.account = null
    } else {
      let accountType = null

      if (!val?.name.includes('card')) {
        accountType = ['current_account', 'savings_account', 'view_account']
      } else if (val?.name === 'credit_card') {
        accountType = ['credit_card']
      } else {
        accountType = ['debit_card']
      }

      this.fields.accounts.items = await this.fetchData({
        query: { name: 'find', model: 'FinancialAccount' },
        filter: {
          _and: [
            { category: { name: { _eq: 'spent' } } },
            {
              person_account: {
                _and: [
                  { active: { _eq: true } },
                  { bank_data: { account_type: { name: { _in: accountType } } } },
                ],
              },
            },
            { active: { _eq: true } },
          ],
        },
      })
    }
  }
  }
